export function getUserInitial(userAccountInfoName: string): string {
  if (!userAccountInfoName) {
    return '-';
  }

  const words: string[] = userAccountInfoName.split(' ');

  if (words.length > 1) {
    return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
  }

  return words[0].slice(0, 2).toUpperCase();
}
