import { useTranslate } from 'hooks/useTranslate';
import { useLocation } from 'react-router-dom';

export function useAppHeaderSubtitle(): string | undefined {
  const location = useLocation();
  const translate = useTranslate();
  const pathParts: string[] = location.pathname.split('/').filter((p) => p);

  if (pathParts.length === 0) {
    return undefined;
  }

  return translate(pathParts[0]);
}

export function AppHeaderSubtitle() {
  const subtitle: string | undefined = useAppHeaderSubtitle();

  if (!subtitle) {
    return null;
  }

  return (
    <>
      <span>|</span>
      <span>{subtitle}</span>
    </>
  );
}
