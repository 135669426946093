import { useMsal } from '@azure/msal-react';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';

export function useSignOut() {
  const { instance } = useMsal();
  const appInsights = useAppInsightsContext();
  const trackSignedOut = useTrackEvent(appInsights, 'Customer Signed Out', {});
  const signOut = () =>
    instance
      .logoutRedirect()
      .then(() => trackSignedOut({}))
      .then(() => window.location.reload());

  return signOut;
}
