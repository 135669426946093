import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'appInsights';
import { LdmView } from 'pages/ldm-view';
import { NCAPage } from 'pages/nca';
import { Layout } from 'components/common/Layout';
import { UnexpectedError } from './alerts';
import { Dashboard } from 'pages/dashboard';

const second: number = 1000;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Stale queries are refetched automatically in the background when:
      // - New instances of the query mount
      // - The window is refocused
      // - The network is reconnected
      // - The query is optionally configured with a refetch interval
      //
      // Specifying a longer staleTime means queries will not refetch their data as often.
      // If you see a refetch that you are not expecting, it is likely because you just focused the window and TanStack Query is doing a refetchOnWindowFocus.
      // During development, this will probably be triggered more frequently, especially because focusing between the Browser DevTools and your app will also cause a fetch, so be aware of that.
      //
      // Source: https://tanstack.com/query/latest/docs/framework/react/guides/important-defaults
      //
      // Current default value is: 20 seconds. Which means a query will only refetch every 20 seconds, UNLESS we invalidate it explicitly via some action or change our query params.
      staleTime: 20 * second,
    },
  },
});

export const appRouterConfig: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        errorElement: <UnexpectedError />,
        element: <Dashboard />,
      },
      {
        path: '/nca/*',
        errorElement: <UnexpectedError />,
        element: <NCAPage />,
      },
      {
        path: '/ldm/*',
        errorElement: <UnexpectedError />,
        element: <LdmView />,
      },
      {
        path: '*',
        errorElement: <UnexpectedError />,
        element: <NotFound />,
      },
    ],
  },
];

export const appRouter = createBrowserRouter(appRouterConfig);

const App = (props: { pca: IPublicClientApplication }) => (
  <MsalProvider instance={props.pca}>
    <AppInsightsErrorBoundary
      onError={() => <h1>Something went wrong</h1>}
      appInsights={reactPlugin}
    >
      <AppInsightsContext.Provider value={reactPlugin}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={appRouter} />
        </QueryClientProvider>
      </AppInsightsContext.Provider>
    </AppInsightsErrorBoundary>
  </MsalProvider>
);

export default App;
