import Badge from '@ingka/badge';
import { NcaStatuses } from '../constants/ncaStatuses';

export interface NCAStatusBadgeProps {
  label: string;
}

export type BadgeColors = 'grey' | 'blue' | 'green' | 'red' | 'orange' | 'yellow';

export function useNCAStatusBadge(label: string): BadgeColors {
  switch (label) {
    case NcaStatuses.DataIncomplete:
      return 'orange';
    case NcaStatuses.AdditionalInformationRequested:
      return 'orange';
    case NcaStatuses.Dispute:
      return 'orange';
    case NcaStatuses.Rejected:
      return 'red';
    case NcaStatuses.Accepted:
      return 'green';
    case NcaStatuses.ManagerApprovalPending:
      return 'yellow';
    case NcaStatuses.Paid:
      return 'green';
    default:
      return 'blue';
  }
}

export function NCASharedStatusBadge(props: NCAStatusBadgeProps) {
  const badgeColor: BadgeColors = useNCAStatusBadge(props.label);

  return (
    <span data-testid={badgeColor}>
      <Badge label={props.label} colour={badgeColor} size="small" />
    </span>
  );
}
