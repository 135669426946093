import { Outlet } from 'react-router-dom';
import 'components/common/Layout.css';
import { AppHeader } from 'components/AppHeader';
import { Navigation } from 'components/navigation';
import { Container } from 'components/common/Container';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { ToastAnchor } from 'components/ToastAnchor';
import { Unauthenticated } from 'components/Unauthenticated';

export function Layout() {
  return (
    <>
      <AuthenticatedTemplate>
        <div className="app-layout">
          <AppHeader />
          <div className="app-nav">
            <Navigation />
          </div>
          <main className="app-main">
            <Container>
              <Outlet />
            </Container>
          </main>
        </div>
        <ToastAnchor />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Unauthenticated />
      </UnauthenticatedTemplate>
    </>
  );
}
