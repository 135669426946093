import React from 'react';
import 'components/common/Subsection.css';

export function Subsection({
  header,
  children,
  className,
}: {
  header?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <section className={'ui-subsection' + (className ? ` ${className}` : '')}>
      {header && <header>{header}</header>}
      {children}
    </section>
  );
}
