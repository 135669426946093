import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import Button from '@ingka/button';
import './LdmViewSystemParameters.css';
import { useState } from 'react';
import { Flex } from 'components/common/Flex';
import { Subsection } from 'components/common/Subsection';

export function LdmViewSystemParameters() {
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [values, setValues] = useState<number[]>([
    Math.round(Math.random() * 1000),
    Math.round(Math.random() * 1000),
    Math.round(Math.random() * 1000),
    Math.round(Math.random() * 1000),
    Math.round(Math.random() * 1000),
    Math.round(Math.random() * 1000),
    Math.round(Math.random() * 1000),
    Math.round(Math.random() * 1000),
    Math.round(Math.random() * 1000),
    Math.round(Math.random() * 1000),
  ]);

  return (
    <Flex dir="column" gap="m">
      <Subsection>
        {Array(10)
          .fill(0)
          .map((_, i) => (
            <FormField
              key={'system-parameter-' + i + 1}
              characterLimit={0}
              fieldHelper={{
                id: `system-parameter-${i + 1}`,
                msg: 'Informative text describing what the system parameter is.',
                type: '',
              }}
              valid="error"
              validation={{
                id: `system-parameter-${i + 1}-error`,
                msg: 'Validation msg text',
                type: 'error',
              }}
            >
              <InputField
                iconPosition="leading"
                id={`system-parameter-${i + 1}-input`}
                label={`System Parameter ${i + 1}`}
                type="number"
                onChange={(e) => {
                  values[i] = Number(e.target.value);
                  setIsChanged(true);
                  setValues(values);
                }}
                value={values[i]}
              />
            </FormField>
          ))}
      </Subsection>
      <Subsection className="ech-ldm-view-system-parameters__controls">
        <Button type="primary" size="small" text="Save changes" disabled={!isChanged} />
        <Button type="danger" size="small" text="Discard changes" disabled={!isChanged} />
      </Subsection>
    </Flex>
  );
}
