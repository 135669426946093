export function businessUnitToString(businessUnit: {
  businessUnitCode: string;
  businessUnitType: string;
  businessUnitSequence?: number;
}): string {
  return (
    `${businessUnit.businessUnitCode}-${businessUnit.businessUnitType}` +
    (businessUnit.businessUnitSequence ? '-' + businessUnit.businessUnitSequence.toString() : '')
  );
}
