import Table from '@ingka/table';
import Text from '@ingka/text';
import { DataContentWrapper } from 'components/common/DataWrapper';
import { NCAOverviewColumnHeader } from './NCAOverviewColumnHeader';
import { NCAOverviewTableRow } from './NCAOverviewTableRow';
import { GetManyNCAsResponse } from 'api/genTypes/dto';
import { QueryColumn } from '../types/query-column';

export interface NCAOverviewTableProps {
  data?: GetManyNCAsResponse;
  isLoading?: boolean;
  error?: unknown;
}

export function NCAOverviewTable({ data, isLoading, error }: NCAOverviewTableProps) {
  const nbrOfItems: number = data?.ncaList?.length || 0;

  const columns: QueryColumn[] = [
    { accessor: 'nonConformityAdviceId' },
    { accessor: 'shipmentId' },
    { accessor: 'tspName' },
    { accessor: 'tspId' },
    { accessor: 'tag' },
    { accessor: 'amountClaimed', isRange: 'number' },
    { accessor: 'currencyClaimed' },
    { accessor: 'costCode' },
    { accessor: 'flowType' },
    { accessor: 'actualDeparture', isRange: 'date' },
    { accessor: 'actualArrival', isRange: 'date' },
    { accessor: 'createDate', isRange: 'date' },
    { accessor: 'lastUpdate', isRange: 'date' },
    { accessor: 'assignee' },
    { accessor: 'status' },
  ];

  return (
    <>
      <Table className="nca-list__table-container" fullWidth>
        <thead>
          <tr>
            {columns.map((c) => {
              return <NCAOverviewColumnHeader {...c} />;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.ncaList?.map((nca) => (
            <NCAOverviewTableRow key={nca.nonConformityAdviceId} row={nca} detailed={false} />
          ))}
        </tbody>
      </Table>
      <DataContentWrapper
        data={data?.ncaList}
        isLoading={isLoading}
        error={error}
      ></DataContentWrapper>
      <Text>{nbrOfItems} items loaded</Text>
    </>
  );
}
