import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ncaAPI } from 'api/ncaAPI';

export const useNcaDetails = () => {
  const { nonConformityAdviceId } = useParams();

  return useQuery({
    queryKey: ['NCA_DETAILS_QUERY', nonConformityAdviceId],
    queryFn: () => ncaAPI.fetchOne({ nonConformityAdviceId }),
    enabled: !!nonConformityAdviceId,
  });
};
