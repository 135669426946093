import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'components/ProtectedBackendPanel/authProvider';
import Button from '@ingka/button';
import { styled } from 'styled-components';
import Text from '@ingka/text';
import { Subsection } from 'components/common/Subsection';
import { AppHeaderLogo } from 'components/AppHeader/components/AppHeaderLogo';
import { useTranslate } from 'hooks/useTranslate';
import { useNavigate } from 'react-router-dom';

const StyledUnauthenticatedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #0058a3;
`;
const StyledUnauthenticatedPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const Unauthenticated = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const login = () => {
    navigate('/');
    instance.loginRedirect(loginRequest);
  };
  const translate = useTranslate();

  return (
    <StyledUnauthenticatedContainer>
      <Subsection>
        <StyledUnauthenticatedPanel>
          <AppHeaderLogo />
          <Text headingSize="m">{translate('Welcome')}</Text>
          <Text headingSize="xs">{translate('loginDescription')}</Text>
          <Button size="medium" type="secondary" onClick={login}>
            {translate('loginWith')}
          </Button>
        </StyledUnauthenticatedPanel>
      </Subsection>
    </StyledUnauthenticatedContainer>
  );
};
