import { NCADetailsEventHistory as NCADetailsEventHistoryDto } from 'api/genTypes/dto';
import { useNCADetailsEventHistory } from './hooks/useNCADetailsEventHistory';
import { DataTable } from 'components/DataTable/DataTable';
import { NCADetailsEventViewModel } from './NCADetailsEventHistory.types';

export function NCADetailsEventHistory({
  eventHistory,
}: {
  eventHistory?: NCADetailsEventHistoryDto;
}) {
  const { eventHistoryColumns, events } = useNCADetailsEventHistory(eventHistory);

  return (
    <>
      <DataTable<NCADetailsEventViewModel> columns={eventHistoryColumns} data={events} />
    </>
  );
}
