import { DataTableColumn, DataTableEntity } from '../DataTable.types';

export interface DataTableCellProps<T extends DataTableEntity> {
  column: DataTableColumn<T>;
  dataItem: T;
}

export function DataTableCell<T extends DataTableEntity>({
  column,
  dataItem: entity,
}: DataTableCellProps<T>) {
  const renderValue = (value: any): string => {
    if (!value) {
      return undefined;
    }

    return String(value);
  };

  const value = entity[column.columnId];

  if (column.columnType === 'boolean') {
    return <input type="checkbox" checked={Boolean(value)} disabled />;
  }

  if (column.columnType === 'date' && value) {
    const dateFormatRegex: RegExp =
      /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}):\d{2}\.\d{3}([+-]\d{2}:\d{2})$/;
    const dateFormatMatch = renderValue(value).match(dateFormatRegex);

    if (dateFormatMatch) {
      const [, datePart, timePart, timezonePart] = dateFormatMatch;

      return <>{`${datePart} ${timePart} ${timezonePart}`}</>;
    }
  }

  return <>{renderValue(entity[column.columnId])}</>;
}
