import { ShipmentStop } from 'api/genTypes/dto';
import { useNCADetailsShipmentStops } from './hooks/useNCADetailsShipmentStops';
import { DataTable } from 'components/DataTable/DataTable';
import { ShipmentStopViewModel } from './NCADetailsShipmentStops.types';

export interface NCADetailsShipmentStopsProps {
  shipmentStops?: ShipmentStop[];
}

export function NCADetailsShipmentStops({ shipmentStops }: NCADetailsShipmentStopsProps) {
  const { columns, shipmentStopViewModels } = useNCADetailsShipmentStops(shipmentStops);

  return <DataTable<ShipmentStopViewModel> columns={columns} data={shipmentStopViewModels} />;
}
