import { Subsection } from 'components/common/Subsection';
import { useNCASearchParams } from '../hooks/useNCASearchParams';
import { useNCAOverviewData } from '../hooks/useNCAOverviewData';
import { NotImplemented } from 'components/alerts';
import Button from '@ingka/button';
import { NCAOverviewTable } from './NCAOverviewTable';
import { useTranslate } from 'hooks/useTranslate';

export function NCAOverview() {
  const t = useTranslate();
  const { clear, params } = useNCASearchParams();
  const { data, isLoading, error } = useNCAOverviewData(params);

  return (
    <>
      <Subsection className="ech-nca-list__table-subsection">
        <Button size="small" onClick={() => clear()}>
          {t('clearFilter')}
        </Button>
        <hr />
        <NCAOverviewTable data={data} isLoading={isLoading} error={error} />
      </Subsection>
      <Subsection>
        <NotImplemented message="Pagination, Multiselct, Assignments, Status Updates." />
      </Subsection>
    </>
  );
}
