import { useQuery } from 'react-query';
import { ncaAPI } from 'api/ncaAPI';
import { QueryParams } from '../types/query-params';

export const useNCAOverviewData = (params: QueryParams) => {
  return useQuery({
    // The params here mean that every time we change our search params,
    // the query is invalidated and must be refetched.
    queryKey: ['NCA_OVERVIEW_QUERY', params],
    queryFn: () => ncaAPI.fetchMany(params),
  });
};
