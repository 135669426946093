import { Link } from 'react-router-dom';
import { NCASharedStatusBadge } from './NCASharedStatusBadge';
import { Datetime } from 'components/common/Datetime';
import { NCADto } from 'api/genTypes/dto';

export interface NCAListTableRowProps {
  row: NCADto;
  detailed?: boolean;
}

export function NCAOverviewTableRow({ row, detailed }: NCAListTableRowProps) {
  return (
    <tr>
      <td>
        {detailed ? (
          <>{row.nonConformityAdviceId}</>
        ) : (
          <Link to={row.nonConformityAdviceId}>{row.nonConformityAdviceId}</Link>
        )}
      </td>
      <td>{row.shipmentId}</td>
      <td>{row.tspName}</td>
      <td>{row.tspId}</td>
      {detailed && <td>{row.sellerId}</td>}
      <td>{row.tag}</td>
      <td>{row.amountClaimed}</td>
      {detailed && <td>{row.amountCalculated}</td>}
      <td>{row.currencyClaimed}</td>
      {detailed && <td>{row.calculatedCurrency}</td>}
      <td>{row.flowType}</td>
      <td>{row.costCode}</td>
      {!detailed && (
        <td>
          <Datetime date={row.actualDeparture} />
        </td>
      )}
      {!detailed && (
        <td>
          <Datetime date={row.actualArrival} />
        </td>
      )}
      <td>
        <Datetime date={row.createDate} />
      </td>
      {!detailed && (
        <td>
          <Datetime date={row.lastUpdate} />
        </td>
      )}
      <td>{row.assignee}</td>
      <td>
        <NCASharedStatusBadge label={row.claimStatus} />
      </td>
    </tr>
  );
}
