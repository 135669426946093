import React from 'react';
import 'components/common/Flex.css';

export interface FlexProps {
  children?: React.ReactNode;
  className?: string;
  dir?: 'column' | 'row';
  gap?: 'xl' | 'l' | 'm' | 's' | 'xs';
}

export function Flex({ children, className, dir, gap }: FlexProps) {
  const classes: string = [
    'ui-flex',
    `ui-flex--${dir || 'row'}`,
    gap ? `ui-gap--${gap}` : null,
    className,
  ]
    .filter((e) => e)
    .join(' ');

  return <div className={classes}>{children}</div>;
}
