import { UnexpectedError } from 'components/alerts';
import styled from 'styled-components';
import { useNCAControls } from './hooks/useNCAControls';
import { NCAControlsView } from './components/NCAControlsView/NCAControlsView';
import { NCAControlsProps } from './NCAControls.types';

export const NCAControlsLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  flex-grow: 1;
  align-items: stretch;
  gap: 1rem;
`;

export const NCAControlsLayoutItem = styled.div`
  flex-grow: 1;
  height: 100%;
`;

export function NCAControls({ nca }: NCAControlsProps) {
  const { handleOnSubmit } = useNCAControls(nca);

  if (!nca) {
    return <UnexpectedError />;
  }

  return (
    <NCAControlsView
      nca={nca}
      isClaimApprovable={nca.isAcceptActionAllowed}
      isClaimRejectable={nca.isRejectActionAllowed}
      isClaimSendableToLDM={nca.isSendToLdmActionAllowed}
      onSubmit={handleOnSubmit}
    />
  );
}
