import { useTranslate } from 'hooks/useTranslate';
import { AppHeaderSubtitle } from './AppHeaderSubtitle';
import Text from '@ingka/text';

export function AppHeaderTitle() {
  const t = useTranslate();

  return (
    <Text tagName="h1" headingSize="m" className="app-header__title">
      <span>{t('appTitle')}</span>
      <AppHeaderSubtitle />
    </Text>
  );
}
