import Hyperlink from '@ingka/hyperlink';
import SSRIcon from '@ingka/ssr-icon';
import linkOut from '@ingka/ssr-icon/paths/link-out';
import { useNavigate, useMatch } from 'react-router-dom';
import './NavigationLink.css';

export interface NavigationLinkProps {
  text: React.ReactNode;
  url: string;
  icon?: (prefix?: string) => React.SVGProps<SVGElement>[];
  external?: boolean;
}

export function NavigationLink({ text, url, icon, external }: NavigationLinkProps) {
  const navigate = useNavigate();
  const fullUrl: string = url + (!url ? '/' : '/*');

  const isActive: boolean = !!useMatch(fullUrl);

  const handleOnClick = (e: React.MouseEvent) => {
    if (external) {
      return;
    }

    e.preventDefault();
    navigate(url);
  };

  return (
    <>
      <Hyperlink
        url={url}
        onClick={handleOnClick}
        className={'navigation-link' + (isActive ? ' navigation-link--active' : '')}
        subtle
        newWindow={external}
      >
        {external && (
          <SSRIcon className="navigation-link__icon" paths={linkOut} aria-label="External link" />
        )}
        {icon && (
          <SSRIcon className="navigation-link__icon" paths={icon} aria-label="Use case link" />
        )}
        {text}
      </Hyperlink>
    </>
  );
}
