import { GetOneNCAResponse } from 'api/genTypes/dto';
import { ncaAPI } from 'api/ncaAPI';
import { useNcaDetails } from 'pages/nca/components/NCADetails/hooks/useNcaDetails';

export function useNCAControls(nca: GetOneNCAResponse) {
  const { refetch } = useNcaDetails();

  const handleOnSubmit = (action: string) => {
    ncaAPI
      .post({
        nonConformityAdviceId: nca.nonConformityAdviceId,
        requested_action: action,
      })
      .then(() => {
        console.log('Success');
      })
      .catch(() => {
        console.error('Error');
      })
      .finally(() => {
        refetch();
      });
  };

  return {
    handleOnSubmit,
  };
}
