import { Routes, Route } from 'react-router-dom';
import { NCADetails } from './components/NCADetails/NCADetails';
import { NCAOverview } from './components/NCAOverview';

export function NCAPage() {
  return (
    <Routes>
      <Route path=":nonConformityAdviceId" element={<NCADetails />} />
      <Route path="" element={<NCAOverview />} />
    </Routes>
  );
}
