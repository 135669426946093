import { ShipmentStop } from 'api/genTypes/dto';
import { ShipmentStopViewModel } from '../NCADetailsShipmentStops.types';
import { DataTableColumn } from 'components/DataTable/DataTable.types';
import { businessUnitToString } from 'utils/businessUnitToString';

export function useNCADetailsShipmentStops(shipmentStops?: ShipmentStop[]) {
  const columns: DataTableColumn<ShipmentStopViewModel>[] = [
    {
      columnId: 'shipmentStop',
      columnType: 'string',
    },
    {
      columnId: 'stopType',
      columnType: 'string',
    },
    {
      columnId: 'plannedArrivalDateTime',
      columnType: 'date',
    },
    {
      columnId: 'plannedDepartureDateTime',
      columnType: 'date',
    },
    {
      columnId: 'actualArrivalDateTime',
      columnType: 'date',
    },
    {
      columnId: 'actualDepartureDateTime',
      columnType: 'date',
    },
    // TODO: Fix with https://inter-ikea.atlassian.net/browse/SCDEXENCA-487
    // {
    //   columnId: 'performanceReasonCode',
    //   columnType: 'string',
    // },
    // {
    //   columnId: 'remarks',
    //   columnType: 'string',
    // },
    // {
    //   columnId: 'cosigner',
    //   columnType: 'string',
    // },
    // {
    //   columnId: 'cosignee',
    //   columnType: 'string',
    // },
    {
      columnId: 'isPOL',
      columnType: 'boolean',
    },
    {
      columnId: 'isPOD',
      columnType: 'boolean',
    },
  ];
  const shipmentStopViewModels: ShipmentStopViewModel[] = shipmentStops
    ? shipmentStops.map((st) => ({
        ...st,
        shipmentStop: businessUnitToString(st.stopAddressReference),
        plannedArrivalDateTime: st.arrival?.plannedDateTime,
        plannedDepartureDateTime: st.departure?.plannedDateTime,
        actualArrivalDateTime: st.arrival?.plannedDateTime,
        actualDepartureDateTime: st.departure?.plannedDateTime,
      }))
    : [];

  return {
    columns,
    shipmentStopViewModels,
  };
}
