import home from '@ingka/ssr-icon/paths/home';
import list from '@ingka/ssr-icon/paths/list';
import clipboard from '@ingka/ssr-icon/paths/clipboard';
import { NavigationLinkProps } from '../components/NavigationLink';
import { useTranslate } from 'hooks/useTranslate';

export interface UseNavigationLinks {
  internalLinks: NavigationLinkProps[];
  externalLinks: NavigationLinkProps[];
}

export function useNavigationLinks(): {
  internalLinks: NavigationLinkProps[];
  externalLinks: NavigationLinkProps[];
} {
  const t = useTranslate();
  const internalLinks: NavigationLinkProps[] = [
    {
      text: 'Home',
      icon: home,
      url: '',
    },
    {
      text: 'NCA',
      icon: list,
      url: '/nca',
    },
    {
      text: 'LDM View',
      icon: clipboard,
      url: '/ldm',
    },
  ];

  const externalLinks: NavigationLinkProps[] = [
    {
      text: t('externalLinkLearningSupport'),
      url: 'https://inter-ikea.atlassian.net/wiki/spaces/NCAHNDL/overview',
      external: true,
    },
    {
      text: t('externalDashboard'),
      url: 'https://app.powerbi.com/groups/85e0285d-3788-4054-a93f-66e4bb9d58eb/reports/d0a928fe-d22e-40aa-962d-b45197a921d7/ReportSectiond33c76312c72b583d4e1?experience=power-bi&clientSideAuth=0',
      external: true,
    },
    {
      text: t('externalNowIt'),
      url: 'https://nowit.ingka.com',
      external: true,
    },
    {
      text: t('externalCarrierHandbook'),
      url: 'https://inter.ikea.net/en/home/Supply/Supply-Chain-Operations/TLSManual-WaysofWorking/Carrier-Handbook',
      external: true,
    },
    {
      text: t('externalLinkWorkingMethod'),
      url: 'https://google.com',
      external: true,
    },
  ];

  return {
    internalLinks,
    externalLinks,
  };
}
