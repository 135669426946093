import create from 'zustand';
import { QueryParams } from '../types/query-params';

export interface UseNCASearchParams {
  params: QueryParams;
  clear: (keys?: string[]) => void;
  update: (key: string, value: string) => void;
  set: (params: QueryParams) => void;
}

export function updateNcaSearchParamsSetFn(key: string, value: string, state: UseNCASearchParams) {
  const params = state.params;

  params[key] = value;

  if (!value) {
    delete params[key];
  }

  return {
    params: {
      ...params,
    },
  };
}

export const useNCASearchParams = create<UseNCASearchParams>((set) => ({
  params: {},
  clear: () =>
    set(() => {
      return {
        params: {},
      };
    }),
  update: (key: string, value: string) =>
    set((state: UseNCASearchParams) => updateNcaSearchParamsSetFn(key, value, state)),
  set: (params: QueryParams) =>
    set(() => {
      return {
        params: params,
      };
    }),
}));
