import Table, { TableHeader, TableBody } from '@ingka/table';
import { Th } from 'components/common/Table';
import { useTranslate } from 'hooks/useTranslate';

export function NCAControlsLog() {
  const t = useTranslate();

  return (
    <Table fullWidth>
      <TableHeader>
        <tr>
          <Th>{t('status')}</Th>
          <Th>{t('date')}</Th>
          <Th>{t('reasonCode')}</Th>
          <Th>{t('comment')}</Th>
          <Th>{t('assignee')}</Th>
        </tr>
      </TableHeader>
      <TableBody>
        {Array(5)
          .fill(0)
          .map((_) => (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
      </TableBody>
    </Table>
  );
}
