import { NotImplemented } from 'components/alerts';
import { NCAControlsActionSelect } from '../NCAControlsActionSelect/NCAControlsActionSelect';
import { NCAControlsLog } from '../NCAControlsLog/NCAControlsLog';
import { NCAControlsLayout, NCAControlsLayoutItem } from '../../NCAControls';
import { NCAControlsViewProps } from './NCAControlsView.types';

export function NCAControlsView({
  onSubmit,
  isClaimApprovable,
  isClaimRejectable,
  isClaimSendableToLDM,
}: NCAControlsViewProps) {
  return (
    <>
      <NCAControlsLayout>
        {/* LOG */}
        <NCAControlsLayoutItem>
          <NCAControlsLog />
          <NotImplemented message="NCAControls.Log" />
        </NCAControlsLayoutItem>

        <NCAControlsLayoutItem>
          <NCAControlsLayout>
            {/* COMMENT */}
            <NCAControlsLayoutItem>
              <NotImplemented message="NCAControls.Comment" />
            </NCAControlsLayoutItem>

            {/* ACTION */}
            <NCAControlsLayoutItem>
              <NCAControlsActionSelect
                isClaimApprovable={isClaimApprovable}
                isClaimRejectable={isClaimRejectable}
                isClaimSendableToLDM={isClaimSendableToLDM}
                onSubmit={onSubmit}
              />
            </NCAControlsLayoutItem>
          </NCAControlsLayout>
        </NCAControlsLayoutItem>
      </NCAControlsLayout>
    </>
  );
}
