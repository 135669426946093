import { NCADetailsEventHistory } from 'api/genTypes/dto';
import { DataTableColumn } from 'components/DataTable/DataTable.types';
import { NCADetailsEventViewModel } from '../NCADetailsEventHistory.types';
import { businessUnitToString } from 'utils/businessUnitToString';

export function useNCADetailsEventHistory(eventHistory?: NCADetailsEventHistory) {
  const eventHistoryColumns: DataTableColumn<NCADetailsEventViewModel>[] = [
    {
      columnId: 'eventCode',
      columnType: 'string',
    },
    {
      columnId: 'eventName',
      columnType: 'string',
    },
    {
      columnId: 'eventLocationName',
      columnType: 'string',
    },
    {
      columnId: 'eventDateTime',
      columnType: 'date',
    },
  ];

  const events: NCADetailsEventViewModel[] = eventHistory?.events.map((event) => ({
    ...event,
    eventLocationName: businessUnitToString(event.eventLocation.locationReference),
  }));

  return {
    eventHistoryColumns,
    events,
  };
}
