import List from '@ingka/list';
import { NavigationLinkProps, NavigationLink } from './NavigationLink';

export interface NavigationListProps {
  links: NavigationLinkProps[];
}

export function NavigationList({ links }: NavigationListProps) {
  return (
    <List className="navigation__list" variation="small">
      {links.map((link) => (
        <li key={'link-' + link.text + '-' + link.url}>
          <NavigationLink
            url={link.url}
            text={link.text}
            icon={link.icon}
            external={link.external}
          />
        </li>
      ))}
    </List>
  );
}
