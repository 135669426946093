import { useState } from 'react';
import { NcaActions } from '../constants/ncaActions';

export function useNCAControlsActionSelect(
  isClaimApprovable: boolean,
  isClaimRejectable: boolean,
  isClaimSendableToLDM: boolean,
  onSubmit?: (action: string) => void
) {
  const [action, setAction] = useState<string | undefined>('');

  const actions: {
    key: string;
    disabled?: boolean;
  }[] = [
    {
      key: NcaActions.Approve,
      disabled: !isClaimApprovable,
    },
    {
      key: NcaActions.Reject,
      disabled: !isClaimRejectable,
    },
    {
      key: NcaActions.SendToManagerApproval,
      disabled: !isClaimSendableToLDM,
    },
  ];

  const handleChange = (selected: string) => {
    if (!selected) {
      setAction(undefined);
    }

    setAction(selected);
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(action);
    }
  };

  const canSubmit: boolean = Boolean(action);

  return {
    action,
    actions,
    handleChange,
    handleSubmit,
    canSubmit,
  };
}
