export interface DatetimeProps {
  date: Date | string;
}

export function Datetime(props: DatetimeProps) {
  if (!props.date) {
    return null;
  }

  if (typeof props.date === 'string') {
    return <time dateTime={new Date(props.date).toISOString()}>{props.date}</time>;
  }

  return <time dateTime={props.date.toISOString()}>{props.date.toISOString()}</time>;
}
