import React from 'react';
import 'components/common/Container.css';

export interface ContainerProps {
  children?: React.ReactNode;
}

export function Container({ children }: ContainerProps) {
  return <div className="container">{children}</div>;
}
