import { useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { useDisclosure } from 'utils/hooks';

const StyledDropdownButton = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const StyledDropdownContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: white;
  gap: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 1000;
`;

export const Dropdown = ({
  children,
  button,
}: {
  children: JSX.Element | JSX.Element[];
  button: JSX.Element;
}) => {
  const disclosure = useDisclosure();
  const dropdownRef = useRef(null);
  const [position, setPosition] = useState({ left: 0 });

  useEffect(() => {
    if (disclosure.isOpen && dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const buttonRect = dropdownRef.current.parentElement.getBoundingClientRect();
      const screenWidth = window.innerWidth;

      let leftPosition = buttonRect.left;

      if (dropdownRect.right > screenWidth) {
        leftPosition = screenWidth - dropdownRect.width;
      }

      setPosition({ left: leftPosition });
    }
  }, [disclosure.isOpen]);

  return (
    <div className="dropdown">
      <StyledDropdownButton onClick={() => disclosure.togle()}>{button}</StyledDropdownButton>
      {disclosure.isOpen && (
        <StyledDropdownContent ref={dropdownRef} style={{ left: `${position.left}px` }}>
          {children}
        </StyledDropdownContent>
      )}
    </div>
  );
};
