import Modal, { Sheets, ModalHeader, ModalFooter, ModalBody } from '@ingka/modal';
import { Stack } from 'components/layout';
import { useTranslate } from 'hooks/useTranslate';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDisclosure } from 'utils/hooks';
import Text from '@ingka/text';
import Button from '@ingka/button';
import filters from '@ingka/ssr-icon/paths/filters';
import SSRIcon from '@ingka/ssr-icon';
import { useNCASearchParams } from '../hooks/useNCASearchParams';
import { QueryColumn } from '../types/query-column';
import Badge from '@ingka/badge';
export const ThNoWrap = styled.th`
  white-space: nowrap;
`;

export const ColumnHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`;

export interface MinMax {
  min?: string;
  max?: string;
}
export function calculateNbrOfFilters(isRange: string, value: string, range: MinMax): number {
  if (isRange) {
    let nbrOfFilters: number = 0;

    if (!!range.min) {
      nbrOfFilters++;
    }

    if (!!range.max) {
      nbrOfFilters++;
    }

    return nbrOfFilters;
  }

  if (value) {
    return 1;
  }

  return 0;
}

export function NCAOverviewColumnHeaderModalFormRange({
  accessor,
  isRange,
  range,
  setRange,
}: QueryColumn & {
  range: MinMax;
  setRange: (range: MinMax) => void;
}) {
  const t = useTranslate();

  return (
    <>
      <label htmlFor={accessor + '-filter-min'}>{t('min')}</label>
      <input
        id={accessor + '-filter-min'}
        value={range.min}
        type={isRange === 'number' ? 'number' : 'datetime-local'}
        onChange={(e) => setRange({ ...range, min: e.target.value })}
      />

      <label htmlFor={accessor + '-filter-max'}>{t('max')}</label>
      <input
        id={accessor + '-filter-max'}
        value={range.max}
        type={isRange === 'number' ? 'number' : 'datetime-local'}
        onChange={(e) => setRange({ ...range, max: e.target.value })}
      />
    </>
  );
}

export function NCAOverviewColumnHeaderModalForm({
  accessor,
  isRange,
  range,
  setRange,
  value,
  setValue,
}: QueryColumn & {
  range: MinMax;
  setRange: (range: MinMax) => void;
  value: string;
  setValue: (value: string) => void;
}) {
  if (isRange) {
    return (
      <NCAOverviewColumnHeaderModalFormRange
        accessor={accessor}
        isRange={isRange}
        range={range}
        setRange={setRange}
      />
    );
  }

  return (
    <input
      id={accessor + '-filter-value'}
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

const useNCAOverviewColumnHeaderModal = ({
  accessor,
  isRange,
  close,
}: QueryColumn & { close: () => void }) => {
  const { params, update } = useNCASearchParams();

  const defaultValue = params[accessor];
  const defaultRange = {
    min: params[accessor + 'Min'],
    max: params[accessor + 'Max'],
  };

  const [value, setValue] = useState<string | undefined>(defaultValue);
  const [range, setRange] = useState<{ min?: string; max?: string }>(defaultRange);

  const handleApply = () => {
    if (isRange) {
      update(accessor + 'Min', range.min);
      update(accessor + 'Max', range.max);
    } else {
      update(accessor, value);
    }

    handleClose();
  };

  const handleClose = () => {
    close();
    setValue(undefined);
    setRange({});
  };

  const handleOpen = () => {
    setValue(defaultValue);
    setRange(defaultRange);
  };

  return {
    handleClose,
    handleOpen,
    handleApply,
    value,
    setValue,
    range,
    setRange,
  };
};

export function NCAOverviewColumnHeaderModal({
  accessor,
  isRange,
  disclosure,
}: QueryColumn & { disclosure: { isOpen: boolean; close: () => void } }) {
  const t = useTranslate();
  const { handleClose, handleOpen, handleApply, value, setValue, range, setRange } =
    useNCAOverviewColumnHeaderModal({ accessor, isRange, close: disclosure.close });

  return (
    <Modal
      handleCloseBtn={() => handleClose()}
      onModalOpened={() => handleOpen()}
      visible={disclosure.isOpen}
      escapable
      style={{ zIndex: '100000' }}
    >
      <Sheets
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button type="primary" text={t('applyFilter')} onClick={handleApply} />
          </ModalFooter>
        }
      >
        <ModalBody>
          <Stack>
            <Text>
              {t('filter')}: {t(accessor)}
            </Text>
            <NCAOverviewColumnHeaderModalForm
              accessor={accessor}
              isRange={isRange}
              value={value}
              setValue={setValue}
              range={range}
              setRange={setRange}
            />
          </Stack>
        </ModalBody>
      </Sheets>
    </Modal>
  );
}

export function NCAOverviewColumnHeaderButton({
  accessor,
  nbrOfFilters,
  disclosure,
}: {
  accessor: string;
  nbrOfFilters: number;
  disclosure: {
    open: () => void;
  };
}) {
  const t = useTranslate();

  const hasFilter: boolean = nbrOfFilters > 0;

  return (
    <Button type="tertiary" size="xsmall" onClick={() => disclosure.open()}>
      <ColumnHeaderContent>
        {t(accessor)}
        <SSRIcon paths={filters} />
        {hasFilter && <Badge colour="grey" label={nbrOfFilters} size="small" />}
      </ColumnHeaderContent>
    </Button>
  );
}

export function NCAOverviewColumnHeader({ accessor, isRange }: QueryColumn) {
  const disclosure = useDisclosure();
  const { params } = useNCASearchParams();

  let nbrOfFilters: number = useMemo(
    () =>
      calculateNbrOfFilters(isRange, params[accessor], {
        min: params[accessor + 'Min'],
        max: params[accessor + 'Max'],
      }),
    [accessor, isRange, params]
  );

  return (
    <ThNoWrap>
      <NCAOverviewColumnHeaderModal accessor={accessor} isRange={isRange} disclosure={disclosure} />
      <NCAOverviewColumnHeaderButton
        accessor={accessor}
        nbrOfFilters={nbrOfFilters}
        disclosure={disclosure}
      />
    </ThNoWrap>
  );
}
