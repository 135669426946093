export const translations: {
  [key: string]: string;
} = {
  appTitle: 'ECH',
  welcome: 'Welcome!',
  ech: 'Extra Cost Handling',
  nca: 'NCA',
  ldm: 'LDM View',
  externalLinks: 'External Links',
  noData: 'No data',
  error: 'An error has occurred.',
  nonConformityAdviceId: 'Claim ID',
  shipmentId: 'Shipment ID',
  tsp: 'TSP',
  tspName: 'TSP',
  tspId: 'TSP ID',
  sellerId: 'Seller ID',
  tag: 'Tag',
  amountClaimed: 'Amount',
  calculatedAmount: 'Calculated Amount',
  currencyClaimed: 'Currency',
  calculatedCurrency: 'Calculated Currency',
  costCode: 'Cost Code',
  flowType: 'Flow',
  actualDeparture: 'Actual Departure',
  actualArrival: 'Actual Arrival',
  createDate: 'Created',
  lastUpdate: 'Last Updated',
  assignee: 'Assignee',
  status: 'Status',
  claimStatus: 'Status',
  loginDescription: 'Log in to IKEA to continue to Extra Cost Handling',
  loginWith: 'Sign in with Inter IKEA',
  ncaNotFound: 'NCA not found.',
  loading: 'Loading...',
  signOut: 'Sign out',
  filter: 'Filter',
  applyFilter: 'Apply Filter',
  min: 'Min',
  max: 'Max',
  externalLinkLearningSupport: 'Learning Support',
  externalDashboard: 'NCA Dashboard',
  externalNowIt: 'NowIT',
  externalCarrierHandbook: 'Carrier Handbook',
  externalLinkWorkingMethod: 'Working Method',
  clearFilter: 'Clear filter',
  noDataTitle: 'No data.',
  noDataMessage: 'No data was found.',
  unexpectedErrorTitle: 'Unexpected error',
  unexpectedErrorMessage: 'Something went wrong',
  notImplementedTitle: 'Not implemented',
  notImplementedMessage: 'This functionality has not yet been implemented.',
  amountCalculated: 'Calculated Amount',
  shipmentStop: 'Shipment Stop',
  stopType: 'Stop Type',
  plannedArrival: 'Planned Arrival',
  plannedDeparture: 'Planned Departure',
  actualArrivl: 'Actual Arrival',
  performanceReasonCode: 'Performance Reason Code',
  remarks: 'Remarks',
  cosigner: 'Cosigner',
  cosignee: 'Cosignee',
  pol: 'POL',
  pod: 'POD',
  shipmentStops: 'Shipment Stops',
  nonConformityAdvice: 'Nonconformity Advice',
  controls: 'Controls',
  saveAndSubmit: 'Save & submit changes',
  selectAction: 'Select action',
  approve: 'Approve',
  reject: 'Reject',
  ldmApproval: 'LDM Approval',
  confirm: 'Confirm',
  areYouSure: 'Are you sure?',
  cancel: 'Cancel',
  eventHistory: 'Event History',
  attachments: 'Attachments',
  handleNca: 'Handle Claim',
  rec24Code: 'Event Code',
  subCode: 'Event Subcode',
  eventName: 'Event Name',
  eventLocation: 'Event Location',
  eventLocationName: 'Event Location',
  eventDateTime: 'Event Time',
  date: 'Date',
  comment: 'Comment',
  reasonCode: 'Reason Code',
  plannedArrivalDateTime: 'Planned Arrival',
  plannedDepartureDateTime: 'Planned Departure',
  actualArrivalDateTime: 'Actual Arrival',
  actualDepartureDateTime: 'Actual Departure',
  isPOL: 'POL',
  isPOD: 'POD',
  eventCode: 'Event Code',
} as const;

export type TranslationKeyType = keyof typeof translations;
