import Table, { TableHeader, TableBody } from '@ingka/table';
import { Th } from 'components/common/Table';
import { useTranslate } from 'hooks/useTranslate';
import { DataTableEntity, DataTableProps } from './DataTable.types';
import { DataTableCell } from './components/DataTableCell';
import { DataContentWrapper } from 'components/common/DataWrapper';

/** DataTable represents a generic data table which renders
 * the provided data in the browser according to the columns provided. */
export function DataTable<T extends DataTableEntity>({
  columns,
  data,
  isLoading,
  error,
}: DataTableProps<T>) {
  const t = useTranslate();
  const shouldRenderTableData: boolean = data && !isLoading && !error;

  return (
    <>
      <Table fullWidth>
        <TableHeader>
          <tr>
            {columns.map((c) => (
              <Th>{t(c.columnId)}</Th>
            ))}
          </tr>
        </TableHeader>
        <TableBody>
          {shouldRenderTableData &&
            data.map((dataItem: T) => (
              <tr>
                {columns.map((c) => (
                  <td>
                    <DataTableCell<T> column={c} dataItem={dataItem} />
                  </td>
                ))}
              </tr>
            ))}
        </TableBody>
      </Table>
      {/* Render loadig, data, and error alerts outside the table body to ensure better formatting */}
      <DataContentWrapper data={data} isLoading={isLoading} error={error} />
    </>
  );
}
