import { GetOneNCAResponse } from 'api/genTypes/dto';
import Table, { TableHeader, TableBody } from '@ingka/table';
import { useTranslate } from 'hooks/useTranslate';
import { NoData } from 'components/alerts';
import { NCASharedStatusBadge } from '../../../NCASharedStatusBadge';
import { NCASharedAssignee } from '../../../NCASharedAssignee';
import { Datetime } from 'components/common/Datetime';
import { Th } from 'components/common/Table';

export interface NCADetailsBasicDataProps {
  nca?: GetOneNCAResponse;
}

export function NCADetailsBasicData({ nca }: NCADetailsBasicDataProps) {
  const t = useTranslate();
  const headerKeys: (keyof GetOneNCAResponse)[] = [
    'nonConformityAdviceId',
    'shipmentId',
    'tspName',
    'tspId',
    'sellerId',
    'tag',
    'amountClaimed',
    'amountCalculated',
    'currencyClaimed',
    'calculatedCurrency',
    'flowType',
    'costCode',
    'createDate',
    'assignee',
    'claimStatus',
  ];

  if (!nca) {
    return <NoData />;
  }

  return (
    <Table fullWidth>
      <TableHeader>
        <tr>
          {headerKeys.map((key) => (
            <Th>{t(key)}</Th>
          ))}
        </tr>
      </TableHeader>
      <TableBody>
        <tr>
          <td>{nca.nonConformityAdviceId}</td>
          <td>{nca.shipmentId}</td>
          <td>{nca.tspName}</td>
          <td>{nca.tspId}</td>
          <td>{nca.sellerId}</td>
          <td>{nca.tag}</td>
          <td>{nca.amountClaimed}</td>
          <td>{nca.amountCalculated}</td>
          <td>{nca.currencyClaimed}</td>
          <td>{nca.calculatedCurrency}</td>
          <td>{nca.flowType}</td>
          <td>{nca.costCode}</td>
          <td>
            <Datetime date={nca.createDate} />
          </td>
          <td>
            <NCASharedAssignee label={nca.assignee} />
          </td>
          <td>
            <NCASharedStatusBadge label={nca.claimStatus} />
          </td>
        </tr>
      </TableBody>
    </Table>
  );
}
