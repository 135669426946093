import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import { LdmViewSystemParameters } from './components/LdmViewSystemParameters';
import { Subsection } from 'components/common/Subsection';

export function LdmView() {
  return (
    <>
      <Tabs
        defaultActiveTab="ldm_tab_params"
        onTabChanged={function noRefCheck() {}}
        tabPanels={[
          <TabPanel key="0" tabPanelId="ldm_tab_params">
            <LdmViewSystemParameters />
          </TabPanel>,
          <TabPanel key="1" tabPanelId="ldm_tab_assignments">
            <Subsection>Todo: LDM Assignment Matrix</Subsection>
          </TabPanel>,
        ]}
        tabs={[
          <Tab
            key="0"
            id="ldm-tab-btn-params"
            tabPanelId="ldm_tab_params"
            text="System Parameters"
          />,
          <Tab
            key="1"
            id="ldm-tab-btn-assignments"
            tabPanelId="ldm_tab_assignments"
            text="Assignments"
          />,
        ]}
      />
    </>
  );
}
