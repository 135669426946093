import Search from '@ingka/search';
import 'components/AppHeader/components/AppHeader.css';
import { AppHeaderLogo } from 'components/AppHeader/components/AppHeaderLogo';
import { AppHeaderUserInfo } from './AppHeaderUserInfo';
import { AppHeaderTitle } from './AppHeaderTitle';

export function AppHeader() {
  return (
    <header className="app-header">
      <div className="app-header__section">
        <AppHeaderLogo />
        <AppHeaderTitle />
        <Search id="search" />
      </div>

      <div className="app-header__section">
        <AppHeaderUserInfo />
      </div>
    </header>
  );
}
